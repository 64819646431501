import { getHeavenlyStem, getEarthlyBranch } from './baziUtils';
import LunarCalendar from 'lunar-calendar';

const heavenlyStems = ["甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸"];
const earthlyBranches = ["子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戌", "亥"];

const MAJOR_SOLAR_TERMS = [
    "立春", "惊蛰", "清明", "立夏", "芒种", "小暑",
    "立秋", "白露", "寒露", "立冬", "大雪", "小寒"
];

const MAJOR_SOLAR_TERMS_DATES = [
    [2, 4], [3, 5], [4, 4], [5, 5], [6, 6], [7, 7],
    [8, 7], [9, 7], [10, 8], [11, 7], [12, 7], [1, 5]
];

/**
 * Determines if the given date is before or after Li Chun (立春).
 * Li Chun is around February 4th and marks the start of the Chinese New Year.
 * @param {Date} date - The date to check.
 * @returns {boolean} True if the date is after Li Chun, false otherwise.
 */
function isAfterLiChun(date) {
    const year = date.getFullYear();
    const liChunDate = new Date(year, 1, 4); // February 4th (Li Chun)
    return date >= liChunDate;
}

/**
 * Gets the Chinese year based on Li Chun.
 * If the date is before Li Chun, the previous year is used.
 * @param {Date} date - The date to check.
 * @returns {number} The Chinese year.
 */
function getChineseYear(date) {
    const year = date.getFullYear();
    return isAfterLiChun(date) ? year : year - 1;
}

/**
 * Calculates the Bazi (Four Pillars of Destiny) for a given birth date, birth time, and gender.
 * @param {string} birthDate - The birth date in 'YYYY-MM-DD' format.
 * @param {string} birthTime - The birth time in 'HH:mm' format (24-hour format).
 * @param {string} gender - The gender of the individual ('male' or 'female').
 * @returns {Object} An object containing the Four Pillars (Year, Month, Day, Hour).
 */
export function calculateBazi(birthDate, birthTime, gender) {
    // Validate inputs
    if (!birthDate || !birthTime || !gender) {
        throw new Error("Invalid input. Please provide a valid birth date, birth time, and gender.");
    }

    // Parse birthDate into year, month, day
    const [year, month, day] = birthDate.split('-').map(Number);
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        throw new Error("Invalid birth date format. Please use 'YYYY-MM-DD'.");
    }

    // Parse birthTime into hour, minute
    const [hour, minute] = birthTime.split(':').map(Number);
    if (isNaN(hour) || isNaN(minute)) {
        throw new Error("Invalid birth time format. Please use 'HH:mm'.");
    }

    // Validate date and time ranges
    if (year < 1900 || year > 2100 || month < 1 || month > 12 || day < 1 || day > 31 || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        throw new Error("Invalid date or time range.");
    }

    // Convert to lunar date using LunarCalendar.calendar
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, day);
    const result = LunarCalendar.calendar(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
    );

    // Find the matching date in the lunar calendar data
    const found = result.monthData.findIndex(
        e =>
            e.year === Number(year) &&
            e.month === Number(month) &&
            e.day === Number(day)
    );

    if (found === -1) {
        throw new Error("Failed to convert Gregorian date to lunar date.");
    }

    // Extract GanZhi values
    const { GanZhiYear, GanZhiMonth, GanZhiDay } = result.monthData[found];
    const GanZhiHour = calculateHour(GanZhiDay.substr(0, 1), hour);

    // Map GanZhi values to the return structure
    return {
        year: { stem: GanZhiYear.substr(0, 1), branch: GanZhiYear.substr(1, 1) },
        month: { stem: GanZhiMonth.substr(0, 1), branch: GanZhiMonth.substr(1, 1) },
        day: { stem: GanZhiDay.substr(0, 1), branch: GanZhiDay.substr(1, 1) },
        hour: { stem: GanZhiHour.substr(0, 1), branch: GanZhiHour.substr(1, 1) },
    };
}

/**
 * Calculates the Heavenly Stem and Earthly Branch for the hour.
 * @param {string} dayStem - The Heavenly Stem of the day.
 * @param {number} hour - The hour (0 to 23).
 * @returns {string} The GanZhi for the hour.
 */
function calculateHour(dayStem, hour) {
    const stemIndex = heavenlyStems.indexOf(dayStem);
    let hourBranchIndex, hourStemIndex;

    if (hour === 23) {
        // Early Rat (子初): 11 PM to 12 AM
        hourBranchIndex = 0; // 子
        hourStemIndex = (stemIndex * 2) % 10;
    } else if (hour === 0) {
        // Late Rat (子正): 12 AM to 1 AM
        hourBranchIndex = 0; // 子
        hourStemIndex = (stemIndex * 2 + 1) % 10;
    } else {
        // Other hours
        hourBranchIndex = Math.floor((hour + 1) / 2) % 12;
        hourStemIndex = (stemIndex * 2 + Math.floor(hour / 2)) % 10;
    }

    return heavenlyStems[hourStemIndex] + earthlyBranches[hourBranchIndex];
}

/**
 * Gets the closest major solar term to the given date.
 * @param {number} year - The year.
 * @param {number} month - The month.
 * @param {number} day - The day.
 * @returns {Object} The closest major solar term.
 */
export function getClosestMajorSolarTerm(year, month, day) {
    const birthDate = new Date(year, month - 1, day);
    let previousTerm = "";
    let nextTerm = "";
    let previousTermDate = null;
    let nextTermDate = null;

    for (let i = 0; i < MAJOR_SOLAR_TERMS_DATES.length; i++) {
        const [termMonth, termDay] = MAJOR_SOLAR_TERMS_DATES[i];
        const termDate = new Date(year, termMonth - 1, termDay);

        if (termDate <= birthDate) {
            previousTerm = MAJOR_SOLAR_TERMS[i];
            previousTermDate = termDate;
        } else {
            nextTerm = MAJOR_SOLAR_TERMS[i];
            nextTermDate = termDate;
            break;
        }
    }

    // If no next term is found, it means the date is after the last major solar term of the year
    // So the next term is the first major solar term of the next year
    if (!nextTerm) {
        nextTerm = MAJOR_SOLAR_TERMS[0];
        nextTermDate = new Date(year + 1, MAJOR_SOLAR_TERMS_DATES[0][0] - 1, MAJOR_SOLAR_TERMS_DATES[0][1]);
    }

    // Calculate the number of days to the next major solar term and from the previous major solar term
    const daysToNext = Math.floor((nextTermDate - birthDate) / (1000 * 60 * 60 * 24));
    const daysFromPrevious = Math.floor((birthDate - previousTermDate) / (1000 * 60 * 60 * 24));

    return {
        next: nextTerm,
        previous: previousTerm,
        daysToNext,
        daysFromPrevious,
        date: nextTermDate, // Add the date of the next major solar term
    };
}

/**
 * Calculates the starting age for the first Luck Pillar.
 * @param {Date} birthDateTime - The birth date and time.
 * @param {boolean} forwardSequence - Whether the Luck Pillars are in forward sequence.
 * @returns {number} The starting age.
 */
function calculateStartingAge(birthDateTime, forwardSequence) {
    const year = birthDateTime.getFullYear();
    const month = birthDateTime.getMonth() + 1;
    const day = birthDateTime.getDate();

    // Get the closest major solar term to the birth date
    const solarTerm = getClosestMajorSolarTerm(year, month, day);

    // Debugging: Log the solar term and its date
    console.log("Closest Major Solar Term:", solarTerm);

    // Calculate the number of days from the birth date to the major solar term
    const solarTermDate = new Date(solarTerm.date);
    const daysDifference = Math.floor((solarTermDate - birthDateTime) / (1000 * 60 * 60 * 24));

    // Debugging: Log the days difference
    console.log("Days Difference:", daysDifference);

    // Calculate the starting age
    const startingAge = Math.floor(daysDifference / 3);

    // Debugging: Log the starting age
    console.log("Starting Age:", startingAge);

    return Math.max(startingAge, 0); // Ensure the starting age is not negative
}

export function calculateLuckPillars(birthDate, gender) {
    const birthDateTime = new Date(birthDate);

    // Convert to lunar date using LunarCalendar.calendar
    const result = LunarCalendar.calendar(
        birthDateTime.getFullYear(),
        birthDateTime.getMonth() + 1,
        birthDateTime.getDate()
    );

    // Find the matching date in the lunar calendar data
    const found = result.monthData.findIndex(
        e =>
            e.year === birthDateTime.getFullYear() &&
            e.month === birthDateTime.getMonth() + 1 &&
            e.day === birthDateTime.getDate()
    );

    if (found === -1) {
        throw new Error("Failed to convert Gregorian date to lunar date.");
    }

    // Extract GanZhiYear and GanZhiMonth for the Year and Month Pillars
    const { GanZhiYear, GanZhiMonth } = result.monthData[found];
    const yearStem = GanZhiYear.substr(0, 1);
    const yearBranch = GanZhiYear.substr(1, 1);
    const monthStem = GanZhiMonth.substr(0, 1);
    const monthBranch = GanZhiMonth.substr(1, 1);

    // Determine if the year is Yang or Yin
    const isYangYear = heavenlyStems.indexOf(yearStem) % 2 === 0;
    const isMale = gender === 'male';

    // Determine the direction of the Luck Pillars
    const forwardSequence = (isYangYear && isMale) || (!isYangYear && !isMale);

    // Calculate the starting age for the first Luck Pillar
    const startingAge = calculateStartingAge(birthDateTime, forwardSequence);

    const luckPillars = [];
    let currentStem = heavenlyStems.indexOf(monthStem); // Start with the Month Stem
    let currentBranch = earthlyBranches.indexOf(monthBranch); // Start with the Month Branch

    for (let i = 0; i < 8; i++) {
        if (forwardSequence) {
            // Forward sequence: Use the next month sequence
            currentStem = (currentStem + 1) % 10;
            currentBranch = (currentBranch + 1) % 12;
        } else {
            // Backward sequence: Use the previous month sequence
            currentStem = (currentStem + 9) % 10; // Equivalent to subtracting 1
            currentBranch = (currentBranch + 11) % 12; // Equivalent to subtracting 1
        }

        const ageRange = `${startingAge + i * 10}-${startingAge + (i + 1) * 10 - 1}`;
        luckPillars.push({
            stem: heavenlyStems[currentStem],
            branch: earthlyBranches[currentBranch],
            ageRange,
        });
    }

    return luckPillars;
}

/**
 * Utility function to get the number of days in the Chinese month of the birth date.
 * @param {Object} lunarDate - The lunar date object.
 * @returns {number} Number of days in the Chinese month.
 */
function getDaysInChineseMonth(lunarDate) {
    return lunarDate.lunarDaysInMonth;
}

/**
 * Utility function to calculate the number of days from birth to the start of the Chinese month.
 * @param {Object} lunarDate - The lunar date object.
 * @returns {number} Number of days from birth to the start of the month.
 */
function getDaysFromBirthToMonthStart(lunarDate) {
    return lunarDate.lunarDay;
}

export const getStemAndBranch = (year) => {
    const stemIndex = (year - 4) % 10;
    const branchIndex = (year - 4) % 12;
    return {
        stem: heavenlyStems[stemIndex],
        branch: earthlyBranches[branchIndex],
    };
};

export const getYears = (birthYear = 1900, length = 101) => Array.from({ length }, (_, i) => birthYear + i);