// src/components/LuckPillars.js
import React from 'react';
import { Table } from '@themesberg/react-bootstrap';

const LuckPillars = ({ luckPillars, getColor, title }) => {
  return (
    <div className="mt-4">
      <h4>{ title }</h4>
      <Table bordered className="text-center">
        <thead>
          <tr>
            {luckPillars.map((pillar, index) => (
              <th key={index}>{pillar.header || pillar.ageRange}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {luckPillars.map((pillar, index) => (
              <td key={index} style={{ color: getColor(pillar.stem), fontSize: '1.5em' }}>
                {pillar.stem}
              </td>
            ))}
          </tr>
          <tr>
            {luckPillars.map((pillar, index) => (
              <td key={index} style={{ color: getColor(pillar.branch), fontSize: '1.2em' }}>
                {pillar.branch}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LuckPillars;
