import React from 'react';
import './Hexagram.css';
import { hexagramNames, trigramElements } from './hexagramMapping';

const determineRelationship = (lowerElement, upperElement) => {
  const relationships = {
    'Water': { 'Wood': 'grow in', 'Metal': 'counter in', 'Water': 'support', 'Earth': 'grow out', 'Fire': 'counter out' },
    'Wood': { 'Fire': 'grow in', 'Earth': 'counter in', 'Wood': 'support', 'Metal': 'grow out', 'Water': 'counter out' },
    'Fire': { 'Earth': 'grow in', 'Water': 'counter in', 'Fire': 'support', 'Wood': 'grow out', 'Metal': 'counter out' },
    'Earth': { 'Metal': 'grow in', 'Wood': 'counter in', 'Earth': 'support', 'Fire': 'grow out', 'Water': 'counter out' },
    'Metal': { 'Water': 'grow in', 'Fire': 'counter in', 'Metal': 'support', 'Earth': 'grow out', 'Wood': 'counter out' }
  };

  return relationships[lowerElement][upperElement] || 'unknown';
};

const Hexagram = ({ code }) => {
  const hexagramName = hexagramNames[code] || { chinese: '', english: 'Unknown', upperTrigram: '', lowerTrigram: '' };
  const upperTrigram = trigramElements[hexagramName.upperTrigram] || { name: '', element: '', color: '' };
  const lowerTrigram = trigramElements[hexagramName.lowerTrigram] || { name: '', element: '', color: '' };
  const relationship = determineRelationship(lowerTrigram.element, upperTrigram.element);

  const renderLine = (line, color) => {
    return line === '1' ? (
      <div className="line solid" style={{ backgroundColor: color }}></div>
    ) : (
      <div className="line broken" style={{ color: color }}>
        <div className="segment" style={{ backgroundColor: color }}></div>
        <div className="segment" style={{ backgroundColor: color }}></div>
      </div>
    );
  };

  return (
    <div className="hexagram">
      <div className="hexagram-header">
        <div className="hexagram-name">
          <span className="chinese">{hexagramName.chinese}</span>
          <span className="english">{hexagramName.english}</span>
        </div>
        <div className="trigrams">
          <div className="trigram" style={{ color: upperTrigram.color }}>
            <span className="name">{upperTrigram.name}</span>
            <span className="element">Element: {upperTrigram.element}</span>
          </div>
          <div className="trigram" style={{ color: lowerTrigram.color }}>
            <span className="name">{lowerTrigram.name}</span>
            <span className="element">Element: {lowerTrigram.element}</span>
          </div>
        </div>
        <div className="relationship">
          <span>{relationship}</span>
        </div>
      </div>
      <div className="lines">
        {[...code].map((line, index) => (
          <div key={index} className="line-wrapper">
            {index < 3 ? renderLine(line, lowerTrigram.color) : renderLine(line, upperTrigram.color)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hexagram;
