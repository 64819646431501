import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import LuckPillars from "../components/Bazi/LuckPillars";
import { Form, Button, Row, Col, Card } from "@themesberg/react-bootstrap";
import { calculateBazi, calculateLuckPillars, getStemAndBranch, getYears, getClosestMajorSolarTerm } from "../helper/BaziCalculator"; // Assume this helper handles Bazi logic
import YearPillars from '../components/Bazi/YearPillars'; // Import the YearPillars component
import styled from "styled-components";

const BaziContainer = styled(Card)`
    overflow: auto;
    cursor: grab;
    user-select: none;

    &:active {
        cursor: grabbing;
    }
`;


function chunk(arr, chunkSize) {
    if (chunkSize <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
    return R;
}

const BaziCalculator = () => {
    // Initialize with default date and time
    const [birthYear, setBirthYear] = useState(new Date().getFullYear());
    const [birthMonth, setBirthMonth] = useState(new Date().getMonth() + 1); // Months are 0-indexed in JavaScript
    const [birthDay, setBirthDay] = useState(new Date().getDate());
    const [birthTime, setBirthTime] = useState(new Date().toTimeString().split(":").slice(0, 2).join(":"));
    const [gender, setGender] = useState("male");
    const [baziResult, setBaziResult] = useState([]);
    const [luckPillars, setLuckPillars] = useState([]);
    const [currentPillarIndex, setCurrentPillarIndex] = useState(0);
    const [solarTerms, setSolarTerms] = useState({ next: "", previous: "", daysToNext: 0, daysFromPrevious: 0 });
    const [currentSolarTerms, setCurrentSolarTerms] = useState({ next: "", previous: "", daysToNext: 0, daysFromPrevious: 0 }); // For current solar terms
    const currentYear = new Date().getFullYear();

    // Generate years, months, and days for dropdowns
    const years = Array.from({ length: 100 }, (_, i) => currentYear - i); // Last 100 years
    const months = Array.from({ length: 12 }, (_, i) => i + 1); // 1 to 12

    // Function to get the number of days in a month
    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    // Update days dropdown when year or month changes
    useEffect(() => {
        const daysInMonth = getDaysInMonth(birthYear, birthMonth);
        if (birthDay > daysInMonth) {
            setBirthDay(daysInMonth); // Reset the day to the maximum valid day for the selected month and year
        }
    }, [birthYear, birthMonth]);

    // Calculate Bazi and luck pillars
    useEffect(() => {
        const birthDate = `${birthYear}-${String(birthMonth).padStart(2, '0')}-${String(birthDay).padStart(2, '0')}`;
        const result = calculateBazi(birthDate, birthTime, gender);
        setBaziResult([
            {
                header: '年柱',
                stem: result.year.stem,
                branch: result.year.branch,
            },
            {
                header: '月柱',
                stem: result.month.stem,
                branch: result.month.branch,
            },
            {
                header: '日柱',
                stem: result.day.stem,
                branch: result.day.branch,
            },
            {
                header: '时柱',
                stem: result.hour.stem,
                branch: result.hour.branch,
            }
        ]);
        const luck = calculateLuckPillars(birthDate, gender);
        setLuckPillars(luck);

        // Find the current luck pillar
        const currentYear = new Date().getFullYear();
        const currentIndex = luck.findIndex(pillar => pillar.startYear <= currentYear && pillar.endYear >= currentYear);
        setCurrentPillarIndex(currentIndex);

        // Calculate solar terms for the birth date
        const solarTermsData = getClosestMajorSolarTerm(birthYear, birthMonth, birthDay);
        setSolarTerms(solarTermsData);
    }, [birthYear, birthMonth, birthDay, birthTime, gender]);

    // Calculate current solar terms
    useEffect(() => {
        const now = new Date();
        const currentSolarTermsData = getClosestMajorSolarTerm(now.getFullYear(), now.getMonth() + 1, now.getDate());
        setCurrentSolarTerms(currentSolarTermsData);
    }, []);

    const toPillarFormat = (year, index) => {
        return {
            year: birthYear + index,
            header: `${birthYear + index} ${index}(${index + 1}) `,
            ...getStemAndBranch(year)
        }
    }
    const yearsPillars = getYears(currentYear, 5).map(toPillarFormat).map((e, i) => {
        return {
            ...e,
            header: currentYear + i,
        }
    })

    const chunkYears = chunk(getYears(birthYear, 100).map(toPillarFormat), 10)
    // Color mapping based on elements
    const elementColors = {
        wood: "#6B8E23",   // Olive Drab
        fire: "#FF4500",   // Orange Red
        earth: "#DAA520",  // Goldenrod
        metal: "#B0C4DE",  // Light Steel Blue
        water: "#1E90FF",  // Dodger Blue
    };

    // Mapping of stems and branches to their elements
    const stemElementMap = {
        甲: "wood", 乙: "wood",
        丙: "fire", 丁: "fire",
        戊: "earth", 己: "earth",
        庚: "metal", 辛: "metal",
        壬: "water", 癸: "water",
    };

    const branchElementMap = {
        子: "water", 丑: "earth", 寅: "wood", 卯: "wood",
        辰: "earth", 巳: "fire", 午: "fire", 未: "earth",
        申: "metal", 酉: "metal", 戌: "earth", 亥: "water",
    };

    // Function to get color for a given stem or branch
    const getColor = (char) => {
        const element = stemElementMap[char] || branchElementMap[char];
        return elementColors[element] || "#000"; // Default to black if not found
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const birthDate = `${birthYear}-${String(birthMonth).padStart(2, '0')}-${String(birthDay).padStart(2, '0')}`;
        const result = calculateBazi(birthDate, birthTime, gender);
        setBaziResult([
            {
                header: '年柱',
                stem: result.year.stem,
                branch: result.year.branch,
            },
            {
                header: '月柱',
                stem: result.month.stem,
                branch: result.month.branch,
            },
            {
                header: '日柱',
                stem: result.day.stem,
                branch: result.day.branch,
            },
            {
                header: '时柱',
                stem: result.hour.stem,
                branch: result.hour.branch,
            }
        ]);
        const luck = calculateLuckPillars(birthDate, gender);
        setLuckPillars(luck);
    };

    useEffect(() => {
        const baziElement = document.getElementById('bazi');
    
        let isDragging = false;
        let startX, scrollLeft;
    
        const handleMouseDown = (e) => {
            isDragging = true;
            startX = e.pageX - baziElement.offsetLeft;
            scrollLeft = baziElement.scrollLeft;
        };
    
        const handleMouseMove = (e) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - baziElement.offsetLeft;
            const walk = (x - startX) * 2; // Adjust the multiplier for faster/slower scrolling
            baziElement.scrollLeft = scrollLeft - walk;
        };
    
        const handleMouseUp = () => {
            isDragging = false;
        };
    
        const handleMouseLeave = () => {
            isDragging = false;
        };
    
        baziElement.addEventListener('mousedown', handleMouseDown);
        baziElement.addEventListener('mousemove', handleMouseMove);
        baziElement.addEventListener('mouseup', handleMouseUp);
        baziElement.addEventListener('mouseleave', handleMouseLeave);
    
        return () => {
            baziElement.removeEventListener('mousedown', handleMouseDown);
            baziElement.removeEventListener('mousemove', handleMouseMove);
            baziElement.removeEventListener('mouseup', handleMouseUp);
            baziElement.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return (
        <Layout>
            <BaziContainer id="bazi" style={{ overflow: 'auto'}}>
                <Card.Header>
                    <h3>八字排盘 (Bazi Calculator)</h3>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <Form.Group controlId="birthYear">
                                    <Form.Label>年 (Year):</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={birthYear}
                                        onChange={(e) => setBirthYear(parseInt(e.target.value))}
                                    >
                                        {years.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group controlId="birthMonth">
                                    <Form.Label>月 (Month):</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={birthMonth}
                                        onChange={(e) => setBirthMonth(parseInt(e.target.value))}
                                    >
                                        {months.map(month => (
                                            <option key={month} value={month}>{month}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group controlId="birthDay">
                                    <Form.Label>日 (Day):</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={birthDay}
                                        onChange={(e) => setBirthDay(parseInt(e.target.value))}
                                    >
                                        {Array.from({ length: getDaysInMonth(birthYear, birthMonth) }, (_, i) => i + 1).map(day => (
                                            <option key={day} value={day}>{day}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={6}>
                                <Form.Group controlId="birthTime">
                                    <Form.Label>出生时间 (HH:MM):</Form.Label>
                                    <Form.Control
                                        type="time"
                                        value={birthTime}
                                        onChange={(e) => setBirthTime(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group controlId="gender">
                                    <Form.Label>性别:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    >
                                        <option value="male">男</option>
                                        <option value="female">女</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                    <LuckPillars title="命盘 (Ming Pan)" luckPillars={baziResult} getColor={getColor} />

                    {/* Bazi Structure by Day Stem and Month Branch */}
                    <h4>八字结构 (Bazi Structure)</h4>
                    <p>
                        <strong>日主 (Day Stem):</strong> {baziResult[2]?.stem} ({stemElementMap[baziResult[2]?.stem] || "Unknown"})<br />
                        <strong>月支 (Month Branch):</strong> {baziResult[1]?.branch} ({branchElementMap[baziResult[1]?.branch] || "Unknown"})
                    </p>

                    {/* Luck Pillars */}
                    {luckPillars.length > 0 && (
                        <LuckPillars
                            title="大运 (Luck Pillars)"
                            luckPillars={luckPillars}
                            getColor={getColor}
                            highlightIndex={currentPillarIndex}
                        />
                    )}

                    {/* Solar Terms for Birth Date */}
                    <h4>节气 (Solar Terms for Birth Date)</h4>
                    <p>
                        <strong>上一个节气 (Previous Solar Term):</strong> {solarTerms.previous} ({solarTerms.daysFromPrevious} 天前)<br />
                        <strong>下一个节气 (Next Solar Term):</strong> {solarTerms.next} ({solarTerms.daysToNext} 天后)
                    </p>

                    {/* Current Solar Terms */}
                    <h4>当前节气 (Current Solar Terms)</h4>
                    <p>
                        <strong>上一个节气 (Previous Solar Term):</strong> {currentSolarTerms.previous} ({currentSolarTerms.daysFromPrevious} 天前)<br />
                        <strong>下一个节气 (Next Solar Term):</strong> {currentSolarTerms.next} ({currentSolarTerms.daysToNext} 天后)
                    </p>

                    {/* Next 5 Years */}
                    <LuckPillars title={`流年 (Next 5 years)`} luckPillars={yearsPillars} getColor={getColor} />

                    {/* Year Pillars */}
                    <h4>百岁 (Year Pillars)</h4>
                    {
                        chunkYears?.map((e, i) => <LuckPillars title={``} luckPillars={e} getColor={getColor} />)
                    }
                </Card.Body>
            </BaziContainer>
        </Layout>
    );
};

export default BaziCalculator;