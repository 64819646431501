exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-user-js": () => import("./../../../src/pages/admin/user.js" /* webpackChunkName: "component---src-pages-admin-user-js" */),
  "component---src-pages-bazi-js": () => import("./../../../src/pages/bazi.js" /* webpackChunkName: "component---src-pages-bazi-js" */),
  "component---src-pages-claim-your-account-js": () => import("./../../../src/pages/claim-your-account.js" /* webpackChunkName: "component---src-pages-claim-your-account-js" */),
  "component---src-pages-create-type-js": () => import("./../../../src/pages/createType.js" /* webpackChunkName: "component---src-pages-create-type-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-human-js": () => import("./../../../src/pages/human.js" /* webpackChunkName: "component---src-pages-human-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-number-finder-js": () => import("./../../../src/pages/numberFinder.js" /* webpackChunkName: "component---src-pages-number-finder-js" */),
  "component---src-pages-number-gua-js": () => import("./../../../src/pages/numberGua.js" /* webpackChunkName: "component---src-pages-number-gua-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-plotter-js": () => import("./../../../src/pages/plotter.js" /* webpackChunkName: "component---src-pages-plotter-js" */),
  "component---src-pages-plotter-v-2-js": () => import("./../../../src/pages/plotter-v2.js" /* webpackChunkName: "component---src-pages-plotter-v-2-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-learning-list-js": () => import("./../../../src/templates/LearningList.js" /* webpackChunkName: "component---src-templates-learning-list-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/Video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

