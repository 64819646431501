export default {
  "EightStars": [
    {
      "Star": "生气",
      "EnglishName": "Vital Energy",
      "NumberCombinations": ["14", "41", "67", "76", "39", "93", "28", "82"],
      "StarNature": {
        "Chinese": "代表贵人的出现，社会的名望，地位。主聪明、智慧，困境中遇到生气星会有新转机。",
        "English": "Represents the appearance of benefactors, social reputation, and status. Indicates intelligence and wisdom, with new opportunities arising in difficult situations."
      },
      "Personality": {
        "Chinese": "生性善良，人缘好，性格开朗、活泼，喜欢开玩笑。看问题客观，办事冷静，易知足，缺乏斗争精神。",
        "English": "Kind-hearted, good interpersonal relationships, cheerful and lively personality, likes to joke. Objective in problem-solving, calm in handling affairs, easily satisfied, lacks a fighting spirit."
      },
      "Marriage": {
        "Chinese": "不顺的婚姻遇到生气星可左右逢源，遇到理想对象能结婚，婚后夫妻之间的矛盾能圆满处理，生活幸福。",
        "English": "In troubled marriages, the presence of the Vital Energy star can bring harmony. Ideal partners can lead to marriage, and marital conflicts can be resolved for a happy life."
      },
      "Academics": {
        "14, 41": "学习成绩好",
        "67, 76": "头脑聪明",
        "39, 93": "成绩一般",
        "28, 82": "平时成绩一般，考试会有较好发挥",
        "English": {
          "14, 41": "Good academic performance",
          "67, 76": "Intelligent",
          "39, 93": "Average performance",
          "28, 82": "Generally average performance but performs well in exams"
        }
      },
      "Wealth": {
        "Chinese": "主贵人相助，易得意外之财。",
        "English": "With the help of benefactors, it's easy to gain unexpected wealth."
      },
      "Career": {
        "Chinese": "贵人相助，即使遇到困难也能化险为夷，绝处逢生，出现良好转机，若出现能量大的数码利于投资。",
        "English": "With the help of benefactors, even difficulties can be overcome, leading to new opportunities. High energy numbers are beneficial for investment."
      },
      "SuitableIndustries": {
        "14, 41": "适合开发工作",
        "67, 76": "适合设计性工作",
        "39, 93": "适合创新工作，尤其是广告业",
        "28, 82": "适合营销、策略性工作",
        "English": {
          "14, 41": "Suitable for development work",
          "67, 76": "Suitable for design work",
          "39, 93": "Suitable for innovative work, especially in advertising",
          "28, 82": "Suitable for marketing and strategic work"
        }
      },
      "Health": {
        "Chinese": "肠胃、心脏、神经系统、眼、鼻、耳易出问题。",
        "English": "Issues may arise with the stomach, heart, nervous system, eyes, nose, and ears."
      }
    },
    {
      "Star": "延年",
      "EnglishName": "Longevity",
      "NumberCombinations": ["19", "91", "78", "87", "34", "43", "26", "62"],
      "StarNature": {
        "Chinese": "智商高，有领导力，组织能力强，业务能力强，不拘小节，有大将之风。",
        "English": "High IQ, leadership, strong organizational and business abilities, not concerned with small matters, has the demeanor of a general."
      },
      "Personality": {
        "Chinese": "遵纪守法，有领导风度，组织观念强，自主性强，思想正统，但固执，喜欢专业之能，钻牛角尖。",
        "English": "Law-abiding, leadership demeanor, strong organizational concept, self-reliant, orthodox thinking but stubborn, loves professional skills and getting to the bottom of things."
      },
      "Marriage": {
        "Chinese": "多半不顺，由于忙事业没有找到另一半，或因自我意识太强不易容忍对方。",
        "English": "Mostly unsuccessful due to career focus or strong self-awareness leading to intolerance of the partner."
      },
      "Wealth": {
        "Chinese": "收入平稳，但求财辛苦，若出现延年星能量比较弱的情况，易出现财来财去现象。同时延年星是一个仓库，代表有所收获。",
        "English": "Stable income but hard to seek wealth. If the Longevity star's energy is weak, it may lead to financial fluctuations. The Longevity star also represents storage, indicating gains."
      },
      "Academics": {
        "19, 91": "成绩非常好",
        "78, 87": "头脑聪明成绩好",
        "34, 43": "成绩一般",
        "26, 62": "成绩较差",
        "English": {
          "19, 91": "Excellent academic performance",
          "78, 87": "Intelligent with good performance",
          "34, 43": "Average performance",
          "26, 62": "Below average performance"
        }
      },
      "Career": {
        "Chinese": "具有领导力，有独当一面的能力，能推动事业稳健发展。",
        "English": "Has leadership qualities, can independently handle tasks, and promotes steady career development."
      },
      "SuitableIndustries": {
        "91, 19": "领导素质，有出国的倾向",
        "78, 87": "解决问题能力强",
        "34, 43": "领导班子里占有一席之地",
        "26, 62": "单位小领导",
        "English": {
          "91, 19": "Leadership qualities, inclination to go abroad",
          "78, 87": "Strong problem-solving skills",
          "34, 43": "Holds a position in leadership",
          "26, 62": "Small unit leader"
        }
      },
      "Health": {
        "Chinese": "心脏、神经、颈椎、肩周炎、关节疼痛等易出问题。",
        "English": "Issues may arise with the heart, nervous system, cervical spine, periarthritis of the shoulder, and joint pain."
      }
    },
    {
      "Star": "天医",
      "EnglishName": "Heavenly Doctor",
      "NumberCombinations": ["13", "31", "68", "86", "49", "94", "72", "27"],
      "StarNature": {
        "Chinese": "表示业绩好，财富多，天资聪颖财运好，以口为职业，如教师、律师、保险员、商业、文艺业等。",
        "English": "Indicates good performance, wealth, intelligent and good financial luck, profession related to speaking, such as teacher, lawyer, insurance agent, business, and arts."
      },
      "Personality": {
        "Chinese": "聪明有智慧，单纯、善良，主见不足，人际关系广泛，爱追求高境界事务，不轻易表露自己。",
        "English": "Intelligent and wise, simple, kind-hearted, lacks assertiveness, has wide interpersonal relationships, loves to pursue high-level matters, doesn't easily reveal oneself."
      },
      "Marriage": {
        "Chinese": "未婚者恋爱交的异性朋友能修成正果，婚后夫妻恩爱幸福。正桃花星，没有结婚的易遇到心上人，已婚者夫妻幸福美满。",
        "English": "Unmarried individuals can turn romantic relationships into marriage, with a happy and loving married life. The positive love star ensures that those not yet married will meet their ideal partners, while married couples will enjoy a harmonious relationship."
      },
      "Wealth": {
        "Chinese": "主得正财，财路宽，财富多，也有意外之财。",
        "English": "Primarily gains legitimate wealth, has broad financial avenues, and significant wealth, including unexpected financial gains."
      },
      "Academics": {
        "13, 31": "学习成绩名列前茅",
        "68, 86": "态度端正，细心认真",
        "49, 94": "走马观花，粗心大意",
        "72, 27": "成绩不稳定，考得好的时候易得意忘形",
        "English": {
          "13, 31": "Top academic performance",
          "68, 86": "Correct attitude, meticulous",
          "49, 94": "Superficial, careless",
          "72, 27": "Unstable performance, overly confident when performing well"
        }
      },
      "Career": {
        "Chinese": "能成就大业，且经济富有。",
        "English": "Can achieve great success and be financially wealthy."
      },
      "Health": {
        "Chinese": "血压高、血粘度高、血脂稠，中风几率高。易得头晕、头痛、胸闷等疾病，若女性占天医星者，易得妇科疾病。",
        "English": "High blood pressure, high blood viscosity, high cholesterol, high risk of stroke. Prone to dizziness, headaches, chest tightness, and for women, gynecological diseases."
      }
    },
    {
      "Star": "伏位",
      "EnglishName": "Stability",
      "NumberCombinations": ["11", "22", "33", "44", "66", "77", "88", "99"],
      "StarNature": {
        "Chinese": "幼年生活平顺，在挫折面前易被动、观望、保守、等待、蓄势待发。代表事业停滞不前，伏位星若在其他心性数码后，也表示状态持续，不是自愿。",
        "English": "Stable childhood life, passive, observant, conservative, waiting for opportunities. Represents career stagnation, and if followed by other star numbers, it indicates a sustained state, not voluntary."
      },
      "Personality": {
        "Chinese": "作风温和，深沉而有耐心，固执保守，处事被动，缺乏自信，非常关爱自己，缺乏安全感，少冒险精神，但也有风趣幽默、善于沟通的一面。",
        "English": "Gentle, deep and patient, stubborn and conservative, passive, lacks confidence, very self-loving, lacks a sense of security, less adventurous, but also humorous and good at communication."
      },
      "Marriage": {
        "Chinese": "占伏位星性的人会忠诚另一半，不会见异思迁，对家庭责任心强，异性朋友不易出现，不易有新恋情。夫妻关系平淡，非常一般，双方缺乏互助。",
        "English": "Those with the Stability star will be loyal to their partners, not easily attracted to others, have a strong sense of family responsibility, and are unlikely to have new romantic relationships. Marital relationships are usually flat and ordinary, with a lack of mutual support."
      },
      "Wealth": {
        "Chinese": "财运平平，没有起色。",
        "English": "Average financial luck, no significant improvement."
      },
      "Academics": {
        "11, 22": "从事长期研究工作",
        "33, 44": "适合固定单一模式的工作",
        "99, 88": "适合行政管理",
        "66, 77": "适合分析一类工作",
        "English": {
          "11, 22": "Engaged in long-term research work",
          "33, 44": "Suitable for fixed, single-mode work",
          "99, 88": "Suitable for administrative management",
          "66, 77": "Suitable for analytical work"
        }
      },
      "Career": {
        "Chinese": "遇事犹豫不决，不敢大胆投资，所以处于滑坡或停滞不前的状态，只有耐心等待机会。在先天生辰或后天数码中没有积极性的数码出现。",
        "English": "Hesitant in decision-making, afraid to invest boldly, thus in a state of decline or stagnation, only patiently waiting for opportunities. No proactive numbers in natal or acquired numerology."
      },
      "Health": {
        "Chinese": "易得心脏、头痛、头晕、失眠等疾病。",
        "English": "Prone to heart problems, headaches, dizziness, insomnia, etc."
      }
    },
    {
      "Star": "绝命",
      "EnglishName": "Total Loss",
      "NumberCombinations": ["12", "21", "69", "96", "84", "48", "73", "37"],
      "StarNature": {
        "Chinese": "人生起伏波动大，易大起大落，两极分化，性格狂妄，易冲动出现意外，易患口舌、牢狱、官司、易出车祸，不会理财，容易轻信别人吸引朋友，但也习惯于独立处事。",
        "English": "Life has significant ups and downs, prone to extremes, arrogant personality, impulsive, likely to encounter accidents, legal troubles, and car accidents, poor financial management, easily trusts others, but also independent in handling matters."
      },
      "Personality": {
        "Chinese": "头脑反应快，爱耍小聪明，生性固执，自以为是，脾气暴躁，争强好胜，不服输，抗上心重，喜欢独来独往不合群。",
        "English": "Quick thinker, likes to show cleverness, stubborn, self-righteous, bad-tempered, competitive, hates losing, rebellious, prefers to be alone and not sociable."
      },
      "Marriage": {
        "Chinese": "未婚者容易一见钟情，闪婚闪离多。会要求对方绝对忠诚，否则后果不吉，家庭生活平衡能力差。",
        "English": "Unmarried individuals are prone to love at first sight, quick marriages and divorces are common. Requires absolute loyalty from partners, otherwise, the consequences are bad, poor ability to balance family life."
      },
      "Wealth": {
        "Chinese": "能赚钱，但不守财，左进右出，若不买固定资产容易负债累累。",
        "English": "Can make money but doesn't save it, money comes and goes quickly, likely to accumulate debt if not investing in fixed assets."
      },
      "Academics": {
        "12, 21": "基础扎实有实力",
        "69, 96": "努力就能考中，适合幕僚",
        "84, 48": "有惊无险，结果不理想",
        "73, 37": "科考有意外之喜，会耍小聪明",
        "English": {
          "12, 21": "Strong foundation, competent",
          "69, 96": "Can succeed with effort, suitable for advisory roles",
          "84, 48": "Narrowly avoids danger, results not ideal",
          "73, 37": "Unexpected success in exams, uses clever tricks"
        }
      },
      "Career": {
        "Chinese": "不适应上班，由于判断思考能力强，故易从事冒险行业，高风险行业（赌博），或者做出谋划策的幕僚工作。",
        "English": "Not suited for regular jobs, strong judgment and thinking skills, thus prone to engage in adventurous or high-risk industries (e.g., gambling), or work in advisory roles."
      },
      "Health": {
        "Chinese": "肝胆肾等易出问题，绝对的吃苦耐劳、任劳任怨，所以易积劳成疾。",
        "English": "Prone to liver, gallbladder, and kidney issues, very hardworking and uncomplaining, thus likely to suffer from overwork-related illnesses."
      }
    },
    {
      "Star": "五鬼",
      "EnglishName": "Five Ghosts",
      "NumberCombinations": ["18", "81", "36", "63", "79", "97", "24", "42"],
      "StarNature": {
        "Chinese": "性格多变，不循正轨。令人难以捉摸。易有血光之灾，命运多变，容易被盗、破财。如果凶星号码后遇到五鬼，表示前面凶星能量会继续恶化，出现：测感情表示感情恶化、夫妻分手；测投资表示会失败，测公司运程会破产。极易招邪。",
        "English": "Variable personality, does not follow norms. Hard to predict. Prone to accidents and financial loss. If followed by a negative star, the negative energy worsens, indicating deteriorating relationships, investment failures, or business bankruptcy. Highly susceptible to negative influences."
      },
      "Personality": {
        "Chinese": "生性多疑，想法多多，说话反复无常，思想不正统，不按常规出牌，内心城府较深，易结识朋友，又极易憎恨别人。往往只着眼于现实，出奇招不按常理出牌，容易走向极端。极易因利己而伤害别人，容易制造麻烦。头脑聪明，多才多艺。",
        "English": "Naturally suspicious, has many ideas, erratic speech, unconventional thinking, does not follow norms, deep inner thoughts, easily makes friends and enemies. Focuses on reality, uses unconventional methods, prone to extremes. Easily harms others for self-benefit, creates trouble. Intelligent and versatile."
      },
      "Marriage": {
        "Chinese": "对待婚姻阴阳不定，感情淡薄，难以做到忠贞不二，容易出现婚外恋，且容易独居造成夫妻分离。36、42心性重者容易有跨国恋。",
        "English": "Inconsistent in marriage, shallow emotions, difficult to remain faithful, prone to extramarital affairs, easily leads to separation. Strongly influenced by 36, 42 numbers, prone to international relationships."
      },
      "Wealth": {
        "Chinese": "债务重、不守财、破财多，喜欢投机取巧。占五鬼星者，往往两极分化严重，要么很有钱，要么债务累累。测财运时五鬼星也代表投资，但其结果需根据其后数码吉凶而定，如其后有文昌、延年或天医则代表成功，否则为不利。",
        "English": "Heavy debt, poor at saving money, frequent financial losses, likes to speculate. Those with the Five Ghosts star often experience extreme financial outcomes, either very wealthy or heavily indebted. Represents investment, success depends on subsequent star numbers; success if followed by Vital Energy, Longevity, or Heavenly Doctor stars, otherwise negative."
      },
      "Academics": {
        "18, 81": "成绩差",
        "36, 63": "喜欢拖拉",
        "79, 97": "喜欢课外活动，影响学习",
        "24, 42": "成绩极差",
        "English": {
          "18, 81": "Poor academic performance",
          "36, 63": "Likes to procrastinate",
          "79, 97": "Likes extracurricular activities, affects studies",
          "24, 42": "Extremely poor performance"
        }
      },
      "Career": {
        "Chinese": "工作时间长，加班加点，适合宗教、命理预测、企业策划以及非正常职业，如运动员等。事业不稳定，经常换行且很容易失业。同时五鬼星代表官运，能量越大官位越高。",
        "English": "Long working hours, suitable for religion, fortune-telling, business planning, and unconventional professions like athletes. Unstable career, frequent job changes, prone to unemployment. Represents political career, higher energy indicates higher positions."
      },
      "Health": {
        "Chinese": "心脏、血压、血液玄幻、生殖系统容易出问题，极易抑郁。",
        "English": "Prone to heart, blood pressure, blood-related issues, reproductive system problems, highly susceptible to depression."
      }
    },
    {
      "Star": "六煞",
      "EnglishName": "Minor Disaster",
      "NumberCombinations": ["16", "61", "74", "47", "83", "38", "29", "92"],
      "StarNature": {
        "Chinese": "忧郁消极，人际关系差，与领导、同事、客户、夫妻关系都不好，神经衰弱时易看到阴性物质，适合从事服务行业。能量小的数码表示事业小，能量大的桃花星者财多。",
        "English": "Melancholic, poor interpersonal relationships, problematic relationships with leaders, colleagues, clients, and spouses, prone to seeing negative entities during nervous breakdowns, suitable for service industries. Low-energy numbers indicate small careers, high-energy peach blossom stars indicate wealth."
      },
      "Personality": {
        "Chinese": "感情丰富，内心寂寞，遇事较为谨慎，喜欢被人照顾，缺乏理性，抗压能力低，常感情用事，金钱观念不强。遇事谨慎小心，犹豫不决，耳根软，容易轻信谣言和传播谣言。",
        "English": "Emotional, lonely, cautious, likes being cared for, lacks rationality, low stress tolerance, often emotional, weak financial sense. Cautious, indecisive, easily believes and spreads rumors."
      },
      "Marriage": {
        "Chinese": "异性缘好，但不容易成功。六煞数码重者婚姻不顺，已婚者容易离婚，未婚者与恋人不协调，易出现婚外恋，三角恋、容易因感情忽略亲情，属于偏桃花。",
        "English": "Good relations with the opposite sex, but unlikely to succeed. Heavily influenced Minor Disaster star indicates troubled marriage, married individuals prone to divorce, unmarried individuals have relationship issues, prone to extramarital affairs, love triangles, and neglecting family due to emotions, considered negative peach blossom."
      },
      "Wealth": {
        "Chinese": "进得少，花得多，不守财，也会因感情之事被骗财，财务也是用于异性和家庭。六煞星性重者，一般财多，反之则易债务累累。",
        "English": "Earns less, spends more, poor at saving, likely to be scammed due to emotions, finances used for the opposite sex and family. Heavily influenced Minor Disaster star generally indicates wealth, otherwise prone to heavy debt."
      },
      "Academics": {
        "16, 61": "公关好手",
        "74, 47": "适合服务业",
        "83, 38": "适合娱乐行业",
        "29, 92": "适合化妆品、美容或零售行业",
        "English": {
          "16, 61": "Good at public relations",
          "74, 47": "Suitable for service industry",
          "83, 38": "Suitable for entertainment industry",
          "29, 92": "Suitable for cosmetics, beauty, or retail industry"
        }
      },
      "Career": {
        "Chinese": "适合做服务行业，不喜欢固定工作。16、61：公关好手；74、47：适合服务业；38,83：适合娱乐行业；92、29：适合化妆品、美容或零售行业。",
        "English": "Suitable for service industry, dislikes fixed jobs. 16, 61: Good at public relations; 74, 47: Suitable for service industry; 83, 38: Suitable for entertainment industry; 92, 29: Suitable for cosmetics, beauty, or retail industry."
      },
      "Health": {
        "Chinese": "易有肠胃、皮肤、失眠、忧郁症等。",
        "English": "Prone to gastrointestinal, skin issues, insomnia, depression, etc."
      }
    },
    {
      "Star": "祸害",
      "EnglishName": "Misfortune",
      "NumberCombinations": ["17", "71", "23", "32", "46", "64", "89", "98"],
      "StarNature": {
        "Chinese": "年少性格叛逆，易钻牛角尖，个人修养差，易有口舌患小人。口才极好，喜欢以口为职业，常受病痛折磨，爱吃喝、爱搬弄是非。若祸害后面加延年，则此人肯定胖。",
        "English": "Rebellious personality in youth, prone to stubbornness, poor personal cultivation, prone to verbal disputes and gossip. Excellent oratory skills, enjoys speaking professions, often suffers from illnesses, loves food and drink, and enjoys creating trouble. If followed by Longevity, the person is likely to be overweight."
      },
      "Personality": {
        "Chinese": "心直口快，说话夸张，固执己见，主观武断。善于心计，重视自尊，若遇当面难堪，绝不会答应。",
        "English": "Outspoken, exaggerated speech, stubborn, subjective and arbitrary. Skilled in scheming, values self-respect, and never agrees when embarrassed in person."
      },
      "Marriage": {
        "Chinese": "夫妻口舌不断，风波不断，易犯偏桃花。",
        "English": "Frequent verbal disputes and conflicts in marriage, prone to infidelity."
      },
      "Wealth": {
        "Chinese": "财运不畅，若祸害在尾，很容易因小人而破财。",
        "English": "Poor financial luck, if Misfortune is at the end, very likely to lose money due to malicious people."
      },
      "Academics": {
        "17, 71": "能说会道，易从事销售、律师、培训",
        "98, 89": "人大代表及培训行业",
        "64, 46": "记者",
        "32, 23": "喜欢接近大众，当歌手、主持人",
        "English": {
          "17, 71": "Articulate, suitable for sales, law, and training",
          "98, 89": "People's representative and training industry",
          "64, 46": "Journalist",
          "32, 23": "Likes to be close to the public, suitable for singer or host"
        }
      },
      "Career": {
        "Chinese": "能说会道，适合从事销售、律师、记者、歌手等职业，关爱民主，当人大代表。",
        "English": "Articulate, suitable for careers in sales, law, journalism, and singing, cares about democracy, becomes a people's representative."
      },
      "Health": {
        "Chinese": "易患淋巴炎、口腔炎、扁桃体炎、呼吸道疾病，容易滋生慢性病，易发车祸和意外伤害，命格中祸害越多则身体越差。",
        "English": "Prone to lymphadenitis, stomatitis, tonsillitis, respiratory diseases, likely to develop chronic illnesses, prone to car accidents and injuries, the more Misfortune in the fate, the poorer the health."
      }
    }
  ]
}