import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { Row, Col, Accordion, Card, Form } from "@themesberg/react-bootstrap";
import { breakNumber, numberToStar, numberToGua, nameChinese } from "../helper/NumberEnergy";
import Hexagram from "../components/IChing/Hexagram";

const IndexPage = ({ location }) => {
  const [inputNumber, setInputNumber] = useState("91143915");
  const [numberStars, setNumberStars] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [guaAnalysis, setGuaAnalysis] = useState(null);

  // Handle numeric input in the textbox
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputNumber(value);
    }
  };

  useEffect(() => {
    if(inputNumber?.length > 1) {
      const _numberStars = breakNumber(inputNumber).map((num) => ({
        number: num,
        star: numberToStar(num),
      }));
      setNumberStars(_numberStars);
  
      if (inputNumber?.length > 1) {
        const kuaBinary = numberToGua(inputNumber);
        setGuaAnalysis(kuaBinary);
      } else {
        setGuaAnalysis(null);
      }
    }
  }, [inputNumber]);

  useEffect(() => {
    const _analysis = [];
    if (inputNumber.includes("0")) {
      _analysis.push("Avoid 0 in the number. 避开0号");
    }

    const avoidForLadies = !!["87", "78", "91", "19"].filter((n) => inputNumber.includes(n)).length;
    if (avoidForLadies) {
      _analysis.push("Avoid 87, 91, 19, 78 for ladies. 87, 91, 19, 78 女生不宜");
    }

    const lastStar = numberStars[numberStars.length - 1];
    if (lastStar) {
      if (["wugui", "liusha", "huohai", "jueming"].includes(lastStar.star.type)) {
        _analysis.push(`Avoid ${lastStar.star.type} at the end. 避开${nameChinese[lastStar.star.type]}尾数`);
      }
    }

    setAnalysis(_analysis);
  }, [numberStars]);

  return (
    <Layout>
      {guaAnalysis && (
        <Accordion>
          <Card>
            <Card.Header>
              <Row className="align-items-center">
                <Col xs={8}>
                  <h3 className="mb-0">Number Gua 数字卦</h3>
                </Col>
                <Col xs={4}>
                  <Form.Group controlId="numberInput" className="mb-0">
                    <Form.Control
                      type="text"
                      value={inputNumber}
                      onChange={handleInputChange}
                      placeholder="Enter numeric value"
                      className="text-end"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={3}>
                  <Hexagram
                    code={guaAnalysis?.main?.binary}
                    trigram={guaAnalysis?.main?.binary}
                    title={`本卦 ${guaAnalysis?.main?.info || ""}`}
                  />
                </Col>
                <Col xs={1}></Col>
                <Col xs={3}>
                  <Hexagram
                    code={guaAnalysis?.process?.binary}
                    trigram={guaAnalysis?.process?.binary}
                    title={`变卦 ${guaAnalysis?.process?.info || ""}`}
                  />
                </Col>
                <Col xs={1}></Col>
                <Col xs={3}>
                  <Hexagram
                    code={guaAnalysis?.outcome?.binary}
                    trigram={guaAnalysis?.outcome?.binary}
                    title={`结果卦 ${guaAnalysis?.outcome?.info || ""}`}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Accordion>
      )}
    </Layout>
  );
};

export default IndexPage;
