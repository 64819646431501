import * as React from "react";
import Box from '@mui/material/Box';
import Seo from "../components/seo";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react'
import Layout from '../components/layout'
import { NumberChip as Chip } from '../components/Chip';
import MobileNumber from "../components/MobileNumber"
import { East } from '@mui/icons-material';
import NumberChart from "../components/NumberChart"
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from '@reach/router';
import Star from '../data/star'
const { useEffect } = React;



const IndexPage = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q');
  const [value, setValue] = React.useState(
    dayjs('1988-09-14T12:00:01'),
  );
  const [number, setNumber] = useState(query)
  const [day, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [elements, setElements] = useState(null)
  const [accordion, setAccordion] = useState([])
  const theme = useTheme();
  const [numberInfo, setNumberInfo] = useState()
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleChange = (newValue) => {
    console.log(newValue)
    setValue(newValue);
  };



  useEffect(() => {
    const accordionData = [

      {
        title: '数字能量升降图 Number Energy Flow Chart',
        content: <Box display="flex" flexDirection="column" gap="8px" justifyContent="center" alignItems="center"><h2> {number} </h2><Box display="flex" flexDirection="row" gap="8px" justifyContent="center" alignItems="center">

          <Box display="flex" flexDirection="row" gap="8px">
            {
              numberInfo?.analysis?.map(e => <>
                {
                  e.quantity > 0 && <Chip size="48px" num={e.elem} opacity="0.2">
                    {e.star}


                  </Chip>
                }
              </>)
            }
          </Box>
        </Box>
          <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"} gap="8px">
            {
              numberInfo?.analysis?.map(e => {

                const star = Star.EightStars.find(i => i.StarName === e.star)
                return e.quantity > 0 && <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                  {
                    <Chip size="48px" num={e.elem} opacity="0.2">
                      {e.star}
                    </Chip>
                  }
                  <p>
                    {star?.PositiveMeaning?.Chinese}

                  </p>

                  <p>
                    {star?.PositiveMeaning?.English}
                  </p>

                  <p>
                    {star?.NegativeMeaning?.Chinese}

                  </p>

                  <p>
                    {star?.NegativeMeaning?.English}
                  </p>
                  
                </Box>
              })
            }
          </Box>
        </Box>
      },
      {
        title: '数字重点分析 Number Main Analysis',
        content: <>
          <Box display="flex" flexDirection="column" gap="8px" justifyContent="center" alignItems="center">
            <h2> {number} </h2></Box>
          {
            numberInfo?.analysis?.map(e => <>
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap="8px">
                你有 <Chip size="48px" num={e.elem} opacity="0.2">{e.quantity}组</Chip>
                <Chip size="48px" num={e.elem} opacity="0.2">
                  {e.star}
                </Chip>
                磁场
              </Box>

              {
                e.description
              }

            </>)
          }


        </>
      }
    ]

    setAccordion(accordionData)
  }, [numberInfo])

  const onNumber = (result) => {
    setNumberInfo(result)
  }


  return (
    <Layout>
      <Seo title="Home" />
      {
        JSON.stringify()
      }
      <Box display="flex" justifyContent="center" flexDirection="column">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ textAlign: 'center' }} justifyContent="center"><h1> 数字能量 Number Energy</h1></Box>
          <Box display="flex" justifyContent="center" flexDirection="row">
            <Tooltip title="Add" placement="right-start">
              <TextField id="outlined-basic" label="Mobile Number" value={number} variant="outlined"
                onChange={e => setNumber(e.target.value)}
              />
            </Tooltip>
          </Box>
          <Box display="flex" justifyContent="center">
            {
              !number && <>
                <h3>Start by typing your mobile Number</h3><br />
                <h3>输入电话号码</h3>
              </>
            }
          </Box>
        </LocalizationProvider>

      </Box>

      <ChartLayout
        accordions={accordion}
      >

        <MobileNumber
          number={number}
          onNumber={onNumber}
        >

        </MobileNumber>

      </ChartLayout>

      <Box display="flex" justifyContent="center" alignItems={"center"} flexDirection="column">
        <NumberChart />
      </Box>
    </Layout>
  )
}

export default IndexPage
