// hexagramMapping.js

export const hexagramNames = {
    '111111': { chinese: '乾', english: 'The Creative', upperTrigram: '111', lowerTrigram: '111' },
    '000000': { chinese: '坤', english: 'The Receptive', upperTrigram: '000', lowerTrigram: '000' },
    '100010': { chinese: '屯', english: 'Difficulty at the Beginning', upperTrigram: '100', lowerTrigram: '010' },
    '010001': { chinese: '蒙', english: 'Youthful Folly', upperTrigram: '010', lowerTrigram: '001' },
    '111010': { chinese: '需', english: 'Waiting (Nourishment)', upperTrigram: '111', lowerTrigram: '010' },
    '010111': { chinese: '訟', english: 'Conflict', upperTrigram: '010', lowerTrigram: '111' },
    '010000': { chinese: '師', english: 'The Army', upperTrigram: '010', lowerTrigram: '000' },
    '000010': { chinese: '比', english: 'Holding Together (Union)', upperTrigram: '000', lowerTrigram: '010' },
    '111011': { chinese: '小畜', english: 'Small Taming', upperTrigram: '111', lowerTrigram: '011' },
    '110111': { chinese: '履', english: 'Treading (Conduct)', upperTrigram: '110', lowerTrigram: '111' },
    '111000': { chinese: '泰', english: 'Peace', upperTrigram: '111', lowerTrigram: '000' },
    '000111': { chinese: '否', english: 'Standstill (Stagnation)', upperTrigram: '000', lowerTrigram: '111' },
    '111101': { chinese: '同人', english: 'Fellowship with Men', upperTrigram: '111', lowerTrigram: '101' },
    '101111': { chinese: '大有', english: 'Great Possession', upperTrigram: '101', lowerTrigram: '111' },
    '001000': { chinese: '謙', english: 'Modesty', upperTrigram: '001', lowerTrigram: '000' },
    '000100': { chinese: '豫', english: 'Enthusiasm', upperTrigram: '000', lowerTrigram: '100' },
    '100110': { chinese: '隨', english: 'Following', upperTrigram: '100', lowerTrigram: '110' },
    '011001': { chinese: '蠱', english: 'Work on the Decayed (Decaying)', upperTrigram: '011', lowerTrigram: '001' },
    '110000': { chinese: '臨', english: 'Approach', upperTrigram: '110', lowerTrigram: '000' },
    '000011': { chinese: '觀', english: 'Contemplation (View)', upperTrigram: '000', lowerTrigram: '011' },
    '100101': { chinese: '噬嗑', english: 'Bite Through', upperTrigram: '100', lowerTrigram: '101' },
    '101001': { chinese: '賁', english: 'Grace', upperTrigram: '101', lowerTrigram: '001' },
    '000001': { chinese: '剝', english: 'Splitting Apart', upperTrigram: '000', lowerTrigram: '001' },
    '100000': { chinese: '復', english: 'Return (The Turning Point)', upperTrigram: '100', lowerTrigram: '000' },
    '100111': { chinese: '無妄', english: 'Innocence (The Unexpected)', upperTrigram: '100', lowerTrigram: '111' },
    '111001': { chinese: '大畜', english: 'Great Taming', upperTrigram: '111', lowerTrigram: '001' },
    '000101': { chinese: '頤', english: 'Nourishment', upperTrigram: '000', lowerTrigram: '101' },
    '101000': { chinese: '大過', english: 'Preponderance of the Great', upperTrigram: '101', lowerTrigram: '000' },
    '011011': { chinese: '習坎', english: 'The Abysmal (Water)', upperTrigram: '011', lowerTrigram: '011' },
    '011001': { chinese: '離', english: 'The Clinging (Fire)', upperTrigram: '011', lowerTrigram: '001' },
    '110001': { chinese: '咸', english: 'Influence (Wooing)', upperTrigram: '110', lowerTrigram: '001' },
    '001001': { chinese: '恆', english: 'Duration', upperTrigram: '001', lowerTrigram: '001' },
    '100011': { chinese: '遯', english: 'Retreat', upperTrigram: '100', lowerTrigram: '011' },
    '100001': { chinese: '大壯', english: 'Great Power', upperTrigram: '100', lowerTrigram: '001' },
    '101110': { chinese: '晉', english: 'Progress', upperTrigram: '101', lowerTrigram: '110' },
    '011101': { chinese: '明夷', english: 'Darkening of the Light', upperTrigram: '011', lowerTrigram: '101' },
    '111001': { chinese: '家人', english: 'The Family (The Clan)', upperTrigram: '111', lowerTrigram: '001' },
    '101111': { chinese: '睽', english: 'Opposition', upperTrigram: '101', lowerTrigram: '111' },
    '001110': { chinese: '蹇', english: 'Obstruction', upperTrigram: '001', lowerTrigram: '110' },
    '011000': { chinese: '解', english: 'Deliverance', upperTrigram: '011', lowerTrigram: '000' },
    '010011': { chinese: '損', english: 'Decrease', upperTrigram: '010', lowerTrigram: '011' },
    '110010': { chinese: '益', english: 'Increase', upperTrigram: '110', lowerTrigram: '010' },
    '111101': { chinese: '夬', english: 'Resoluteness', upperTrigram: '111', lowerTrigram: '101' },
    '010010': { chinese: '姤', english: 'Coming to Meet', upperTrigram: '010', lowerTrigram: '010' },
    '110011': { chinese: '萃', english: 'Gathering Together (Massing)', upperTrigram: '110', lowerTrigram: '011' },
    '001011': { chinese: '升', english: 'Pushing Upward', upperTrigram: '001', lowerTrigram: '011' },
    '110100': { chinese: '困', english: 'Oppression (Exhaustion)', upperTrigram: '110', lowerTrigram: '100' },
    '111110': { chinese: '井', english: 'The Well', upperTrigram: '111', lowerTrigram: '110' },
    '011100': { chinese: '革', english: 'Revolution (Molting)', upperTrigram: '011', lowerTrigram: '100' },
    '101111': { chinese: '鼎', english: 'The Cauldron', upperTrigram: '101', lowerTrigram: '111' },
    '011010': { chinese: '震', english: 'The Arousing (Shock, Thunder)', upperTrigram: '011', lowerTrigram: '010' },
    '001010': { chinese: '艮', english: 'The Keeping Still, Mountain', upperTrigram: '001', lowerTrigram: '010' },
    '110110': { chinese: '漸', english: 'Development (Gradual Progress)', upperTrigram: '110', lowerTrigram: '110' },
    '001100': { chinese: '歸妹', english: 'Marrying Maiden', upperTrigram: '001', lowerTrigram: '100' },
    '110101': { chinese: '豐', english: 'Abundance (Fullness)', upperTrigram: '110', lowerTrigram: '101' },
    '011101': { chinese: '旅', english: 'The Wanderer', upperTrigram: '011', lowerTrigram: '101' },
    '100110': { chinese: '巽', english: 'The Gentle (The Penetrating, Wind)', upperTrigram: '100', lowerTrigram: '110' },
    '010010': { chinese: '兌', english: 'The Joyous, Lake', upperTrigram: '010', lowerTrigram: '010' },
    '110011': { chinese: '渙', english: 'Dispersion (Dissolution)', upperTrigram: '110', lowerTrigram: '011' },
    '011011': { chinese: '節', english: 'Limitation', upperTrigram: '011', lowerTrigram: '011' },
    '101100': { chinese: '中孚', english: 'Inner Truth', upperTrigram: '101', lowerTrigram: '100' },
    '001100': { chinese: '小過', english: 'Small Preponderance', upperTrigram: '001', lowerTrigram: '100' },
    '101001': { chinese: '既濟', english: 'After Completion', upperTrigram: '101', lowerTrigram: '001' },
    '100101': { chinese: '未濟', english: 'Before Completion', upperTrigram: '100', lowerTrigram: '101' }


};

export const trigramElements = {
    '111': { name: 'Heaven', element: 'Metal', color: 'white' },
    '000': { name: 'Earth', element: 'Earth', color: 'yellow' },
    '100': { name: 'Thunder', element: 'Wood', color: 'green' },
    '011': { name: 'Wind', element: 'Wood', color: 'green' },
    '010': { name: 'Water', element: 'Water', color: 'blue' },
    '101': { name: 'Fire', element: 'Fire', color: 'red' },
    '001': { name: 'Mountain', element: 'Earth', color: 'yellow' },
    '110': { name: 'Lake', element: 'Metal', color: 'white' },
};
