

export const combo = {
    "shengqi1": ["14", "41"],
    "shengqi2": ["67", "76"],
    "shengqi3": ["39", "93"],
    "shengqi4": ["28", "82"],

    "tianyi1": ["13", "31"],
    "tianyi2": ["68", "86"],
    "tianyi3": ["49", "94"],
    "tianyi4": ["27", "72"],

    "yannian1": ["19", "91"],
    "yannian2": ["78", "87"],
    "yannian3": ["34", "43"],
    "yannian4": ["26", "62"],

    "fuwei1": ["11", "22"],
    "fuwei2": ["88", "99"],
    "fuwei3": ["66", "77"],
    "fuwei4": ["33", "44"],

    "liusha1": ["16", "61"],
    "liusha2": ["47", "74"],
    "liusha3": ["38", "83"],
    "liusha4": ["29", "92"],

    "huohai1": ["17", "71"],
    "huohai2": ["89", "98"],
    "huohai3": ["46", "64"],
    "huohai4": ["23", "32"],

    "wugui1": ["18", "81"],
    "wugui2": ["79", "97"],
    "wugui3": ["36", "63"],
    "wugui4": ["24", "42"],

    "jueming1": ["12", "21"],
    "jueming2": ["69", "96"],
    "jueming3": ["48", "84"],
    "jueming4": ["37", "73"],

    "good05": ["01", "03", "05", "06", "07", "08", "15", "25", "30", "35", "45", "51", "52", "57", "58", "65", "75", "85", "95"],
    "bad05": ["02", "04", "09", "10", "20", "40", "50", "53", "54", "55", "56", "59", "60", "70", "80", "90"]

}


export const numberToStar = (num) => {
    return Object.keys(combo).reduce((prev, k) => {
        const check = `${num}`;
        
        if(prev) {
            return prev;
        }
        if(combo[k].includes(check)) {

            return {
                type: k.substr(0, k.length - 1),
                level: k.substr(-1)
            }
        } else {
            return '';
        }
    }, '')
}

const GOOD_NUMBER = [
    ...combo["shengqi1"],
    ...combo["shengqi2"],
    ...combo["shengqi3"],
    ...combo["shengqi4"],

    ...combo["tianyi1"],
    ...combo["tianyi2"],
    ...combo["tianyi3"],
    ...combo["tianyi4"],

    ...combo["yannian1"],
    ...combo["yannian2"],
    ...combo["yannian3"],
    ...combo["yannian4"],

    ...combo["fuwei1"],
    ...combo["fuwei2"],
    ...combo["fuwei3"],
    ...combo["fuwei4"],
]

const removeFiveZero = (str) => {
    return str.split("").reduce((prev, next) => {
  
      if(next !== "0" && next !== "5") {
        return prev + next;
      } else {
        return prev
      }
    }, "")
  }
  
export const breakNumber = (str) => {
    const result = []
    const number = removeFiveZero(str)
    for(let i = 0; i < number.length; i++) {
    let subNumber = number[i]
    if(["0", "5"].includes(subNumber) || i === number.length - 1) {
        continue;
    }
    //
    let nextNumberIndex = i + 1; 
    while(true) {

        if(nextNumberIndex  === number.length) {
        break;
        }
        subNumber = subNumber + number[nextNumberIndex]
        if(!["0", "5"].includes(subNumber[subNumber.length - 1])) {
        break;
        }
        nextNumberIndex++;
    }
    result.push(subNumber)
    }
    return result
}


export const isGoodNumber = num => {
    const chunk = breakNumber(num)
    let noOfFive = 0;
    let badNumber = 0
    return chunk.reduce((prev, next, i) => {
        if(noOfFive > 1) {
            return false;
        }
        if(num.includes('5')) {
            noOfFive = noOfFive + 1;
        }
        if(num.includes('0')) {
            return false;
        }
        if(!prev) {
            return prev;
        }
        if( !GOOD_NUMBER.includes(next) ) {
            badNumber = badNumber + 1
        }

        if(badNumber > 1) {
            return false
        }
        return true
    }, true)
}

const fiveElement = ['ME', 'WA', 'WO', 'FI', 'EA']


const isAuspicious = (hostElement, subjectElement) => {
    
    const hostElementIndex = fiveElement.findIndex(e => e === hostElement)
    const relationshipMap = !hostElementIndex ? fiveElement : [...fiveElement.slice(hostElementIndex), ...fiveElement.slice(0, hostElementIndex)]
    const subjectElementIndex = relationshipMap.findIndex(e => e === subjectElement)
    const ratingChart = {
        0: 'Good',
        1: 'Bad',
        2: 'Good',
        3: 'Bad',
        4: 'Good',
    }

    console.log(`${hostElement} ${subjectElement} ${ratingChart[subjectElementIndex]}`)
    return ratingChart[subjectElementIndex]
}

const getHexPermutation = (binary, changeLine) => {
    console.log(binary)
    const trigramElement =  {
        '111': 'ME', 
        '110': 'WO', 
        '101': 'FI', 
        '100': 'WO', 
        '011': 'ME', 
        '010': 'WA',  
        '001': 'EA', 
        '000': 'EA', 
    }
    const bianGua = binary.split('').map((int, i) => {
        if(i === changeLine -  1) {
            return int === '0' ? '1' : '0'
        } else {
            return int
        }
    })

    const hostAtUpper = !(changeLine > 3);
    const getAuspicious = (binary, hostAtUpper) => {
        if(hostAtUpper) {
            return isAuspicious(trigramElement[binary.slice(3)], trigramElement[binary.slice(0, 3)]);
        } else {
            return isAuspicious(trigramElement[binary.slice(0, 3)], trigramElement[binary.slice(3)]);
        }
    }

    return {
        main: {
            binary,
            info: getAuspicious(binary, hostAtUpper)
        },
        process: {
            binary: binary.slice(1, 4) + binary.slice(2, 5),
            info: getAuspicious(binary.slice(1, 4) + binary.slice(2, 5), hostAtUpper)
        },
        outcome: {
            binary: bianGua.join(''),
            info: getAuspicious(bianGua.join(''), hostAtUpper)
        }
    }
} 


export const numberToGua = (num) => {

    const trigramMap = ['111', '110', '101', '100', '011', '010', '001', '000']
    const element = ['ME', 'WO', 'FI', 'WO', 'ME' ,'WA', 'EA', 'EA']
    

    const upperKuaLength = Math.floor(num.length / 2);
    const upperKuaNumber = num.slice(0, upperKuaLength).replaceAll('0', '8')
    const lowerKuaNumber = num.slice(upperKuaLength).replaceAll('0', '8')
    const addAllNumber = (numStr) => numStr.split('').reduce((prev, next) => {
        return prev + Number(next)
    }, 0)
    const upperKua = addAllNumber(upperKuaNumber) % 8;
    const lowerKua = addAllNumber(lowerKuaNumber) % 8;
    const changeLine = (addAllNumber(upperKuaNumber) + addAllNumber(lowerKuaNumber)) % 6
    const changeLineNumber = changeLine === 0 ? 6 : changeLine;
    const hostAtUpper = changeLineNumber > 3;
    const mainUpperElement = element[upperKua - 1];
    const mainLowerElement = element[lowerKua - 1];

    return {
        ...getHexPermutation(trigramMap[lowerKua === 0 ? 7 : lowerKua - 1] + trigramMap[upperKua === 0 ? 7 : upperKua - 1], changeLineNumber)
    }
  }

  export const nameChinese = {
    wugui: '五鬼',
    liusha: '六杀',
    huohai: '祸害',
    jueming: '绝命',
    tianyi: '天医',
    fuwei: '伏位',
    shengqi: '生气',
    yannian: '延年',
  }
